import React, {useEffect, useState, Fragment} from 'react'
import apiClient from '../../request/http-common';

const Investors = () => {
  const [plans, setPlans] = useState([]);
  const getAmount = (amount) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);

  useEffect(() => {
    apiClient.get('/api/investment-plans')
        .then((response) => {
          if(response.data.status === 'success'){
             setPlans(response.data.top_investments);        
          }
        });
  }, []);

  return (
    <section className="investors-section pt-120 pb-120">
  
    {plans.length > 0 && 
    <Fragment>
  <div className="bg-shape bg_img" style={{backgroundImage: 'url(assets/images/investor-bg.png)'}} />
    <div className="container">
    <div className="row">
      <div className="col-lg-6 col-md-8">
        <div className="section-header text-white wow fadeIn" data-wow-duration="0.8s" data-wow-delay="0.5s">
          <span className="section-subtitle">Take a look at our</span>
          <h2 className="section-title">Top 10 Investors</h2>
          <p>A look at the top ten investors of all time and the strategies they used to make their money.</p>
        </div>
      </div>
    </div>
    <div className="investor-slider owl-carousel">
      {plans.map(investment =>(
        <div key={investment.id} className="investor-item text-center">
          <div className="thumb">
            <img src={investment.user.profile} alt="image" />
            <a href="#0" className="icon"><i className="fa fa-linkedin" /></a>
          </div>
          <div className="content">
            <h4 className="name"><a href="#0">{investment.user.name}</a></h4>
            <span className="amount">{getAmount(investment.amount_invested)}</span>
            <p>Invested In {investment.source}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
  </Fragment>
    }
</section>


  )
}

export default Investors
