import React from 'react'


const Banner2 = () => {
  return (
   <section style={{marginTop: '-130px'}} className="banner-section banner--five bg_img" data-background="https://res.cloudinary.com/dzxyvdq14/image/upload/v1698568003/graph-chart-stock-trade-market-growth-graph-business-digital-investment-bullish-point-up-trend-generative-ai_1423-8923.jpg">
  <div className="container-fluid" style={{backgroundColor: 'rgba(3, 59, 12, 0.68)'}}>
    <div className='container'>
    <div className="row">
      <div className="col-lg-12">
        <div className="banner-content-area">
          <div id="particles-js" style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, zIndex: 1}} />
          <div className="banner-content text-center">
            <h2 className="banner-title" style={{color: '#fff', fontWeight: 'bolder', paddingTop: '50px'}}>Invest With Confidence And Harvest The Rewards, Our Mission Is Your Financial Success</h2>
            <a style={{backgroundColor: '#ff0000', position: 'relative', zIndex: 4, color: '#ffffff', fontWeight: 'bolder'}} href="https://account.triumph-fx.com" className="btn btn-round">Get Started Now</a>
            
            <div className="banner-thumb anim-bounce">
              <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1698569634/triumpsignal.png" alt="image" />
              </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Banner2
