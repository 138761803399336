import React from 'react'

const Counter = () => {
  return (
    <div className="counter-sections" style={{backgroundColor: '#06221a'}}>
  <div className="container" >
    <div className="row" >
      <div className="col-lg-12">
        <div className="counter-area d-flex justify-content-between" style={{backgroundColor: '#0f311f'}}>
          <div className="counter-item">
            <div className="counter-icon">
              <img src="assets/images/icons/counter/1.svg" alt="icon" />
            </div>
            <div className="counter-content">
              <span style={{color: '#fff'}}>$</span>
              <span className="counter" style={{color: '#fff'}}>961</span>
              <span style={{color: '#fff'}}>k</span>
              <span className="caption" style={{color: '#fff'}}>Invested in Pitches</span>
            </div>
          </div>{/* counter-item end */}
          <div className="counter-item">
            <div className="counter-icon">
              <img src="assets/images/icons/counter/2.svg" alt="icon" />
            </div>
            <div className="counter-content">
              <span style={{color: '#fff'}}>$</span>
              <span className="counter" style={{color: '#fff'}}>634</span>
              <span style={{color: '#fff'}}>k</span>
              <span className="caption" style={{color: '#fff'}}>Registered Members</span>
            </div>
          </div>{/* counter-item end */}
          <div className="counter-item">
            <div className="counter-icon">
              <img src="assets/images/icons/counter/3.svg" alt="icon" />
            </div>
            <div className="counter-content">
              <span style={{color: '#fff'}}>$</span>
              <span className="counter" style={{color: '#fff'}}>14</span>
              <span style={{color: '#fff'}}>k</span>
              <span className="caption" style={{color: '#fff'}}>Average Investment</span>
            </div>
          </div>{/* counter-item end */}
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Counter
