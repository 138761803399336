import React from 'react'

const Partner = () => {
  return (
    <section className="partner-section pt-lg-120 pb-120">
  <div className="container">
    <div className="row justify-content-between">
      <div className="col-lg-5">
        <div className="partner-wrapper">
          <div className="partner-single wow zoomIn" data-wow-duration="0.3s" data-wow-delay="0.5s">
            <div className="partner-single-inner">
              <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1643286877/coinbase-new4201.jpg" alt="image" />
            </div>
          </div>{/* partner-single end */}
          <div className="partner-single wow zoomIn" data-wow-duration="0.5s" data-wow-delay="0.5s">
            <div className="partner-single-inner">
              <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1643286848/binance-logo-1.png" alt="image" />
            </div>
          </div>{/* partner-single end */}
          <div className="partner-single wow zoomIn" data-wow-duration="0.7s" data-wow-delay="0.5s">
            <div className="partner-single-inner">
              <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1643287041/coingecko-logo-vector.png" alt="image" />
            </div>
          </div>{/* partner-single end */}
          <div className="partner-single wow zoomIn" data-wow-duration="0.9s" data-wow-delay="0.5s">
            <div className="partner-single-inner">
              <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1643286899/vertical_blue.png" alt="image" />
            </div>
          </div>{/* partner-single end */}
        </div>
      </div>
      <div className="col-lg-6">
        <div className="section-header text-lg-left text-center">
          <span className="section-subtitle">Let’s see our</span>
          <h2 className="section-title">Trusted Partners</h2>
          <p>We’re committed to making our clients successful by becoming their partners and trusted advisors .behoof believes in being your trusted partner and earning that trust through confidence and performance in service and support.</p>
          <a href="#0" className="btn btn-primary text-small">join with us</a>
          <img src='https://res.cloudinary.com/dzxyvdq14/image/upload/v1698654887/triuphfxdocs.jpg'/>
        </div>
      </div>
    </div>
  </div>
</section>


  )
}

export default Partner
