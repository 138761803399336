import React from 'react'

const ContextWinner = () => {
  return (
    <section className="contest-winner-section pt-120 pb-120">
  <div className="shape"><img src="assets/images/elements/contest-shape.png" alt="image" /></div>
  <div className="container">
    <div className="row justify-content-between">
      <div className="col-xl-6 col-lg-6">
        <div className="contest-winner-content">
          <div className="section-header text-lg-left text-center">
            <span className="section-subtitle">Take a look at our latest</span>
            <h2 className="section-title">Contest Winners</h2>
            <p>The contest is based on sales from your referrals.The person with the most total  referral's revenue will get the Grand Prize. The more revenue your referrals produce the bigger chance for you to be on top.</p>
            <a href="#0" className="btn btn-primary">read more</a>
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-lg-6">
        <div className="contest-winner-slider owl-carousel">
          <div className="contest-winner-item">
            <div className="thumb">
              <img src="assets/images/contest-winner/1.png" alt="image" />
              <span className="icon"><img src="assets/images/icons/contest-winner/trophy.svg" alt="icon" /></span>
              <span className="amount">$1,000.00</span>
              <span className="date">feb 2019</span>
            </div>
          </div>{/* contest-winner-item end */}
          <div className="contest-winner-item">
            <div className="thumb">
              <img src="assets/images/contest-winner/1.png" alt="image" />
              <span className="icon"><img src="assets/images/icons/contest-winner/trophy.svg" alt="icon" /></span>
              <span className="amount">$1,000.00</span>
              <span className="date">feb 2019</span>
            </div>
          </div>{/* contest-winner-item end */}
          <div className="contest-winner-item">
            <div className="thumb">
              <img src="assets/images/contest-winner/1.png" alt="image" />
              <span className="icon"><img src="assets/images/icons/contest-winner/trophy.svg" alt="icon" /></span>
              <span className="amount">$1,000.00</span>
              <span className="date">feb 2019</span>
            </div>
          </div>{/* contest-winner-item end */}
          <div className="contest-winner-item">
            <div className="thumb">
              <img src="assets/images/contest-winner/1.png" alt="image" />
              <span className="icon"><img src="assets/images/icons/contest-winner/trophy.svg" alt="icon" /></span>
              <span className="amount">$1,000.00</span>
              <span className="date">feb 2019</span>
            </div>
          </div>{/* contest-winner-item end */}
          <div className="contest-winner-item">
            <div className="thumb">
              <img src="assets/images/contest-winner/1.png" alt="image" />
              <span className="icon"><img src="assets/images/icons/contest-winner/trophy.svg" alt="icon" /></span>
              <span className="amount">$1,000.00</span>
              <span className="date">feb 2019</span>
            </div>
          </div>{/* contest-winner-item end */}
        </div>
      </div>
    </div>
  </div>
</section>


  )
}

export default ContextWinner
