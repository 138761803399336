import React, {useEffect, useState} from 'react';
import apiClient from '../../request/http-common';

const Invest = () => {
  const [investments, setinvestments] = useState([]);
  const getAmount = (amount) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);

  useEffect(() => {
    apiClient.get('/api/investment-plans')
        .then((response) => {
          if(response.data.status === 'success'){
             setinvestments(response.data.investments);        
          }
        });
  }, []);

  return (
    <section className="invest-section pt-120 pb-120 bg_img" style={{backgroundColor: '#051913'}}>
      {investments.length > 0 &&
       <div className="container-fluid"  style={{backgroundColor: '#045f45'}}>
        <div className='container'>
       <div className="row justify-content-center">
         <div className="col-lg-12">
           <div className="section-header text-center text-white">
             <span className="section-subtitle">The smarter way to invest!</span>
             <h2 className="section-title">Start investing! It’s never too late</h2>
             <p>Make sound investment decisions with the help of our research &amp; analytical assets. You may make additional deposits at any time. All our payments are instant payments.</p>
           </div>
         </div>
       </div>
       <div className="row">
         <div className="col-lg-12">
           <div className="invest-table-area wow fadeInUp" data-wow-duration="0.5s" data-wow-delay="0.5s">
             <table>
               <thead>
                 <tr>
                   <th>User</th>
                   <th>Amount</th>
                   <th>Source</th>
                   <th>Duration</th>
                 </tr>
               </thead>
               <tbody>
                 {investments.map(investment => (
                   <tr key={investment.id}>
                   <td>
                     <div className="person-details">
                       <div className="thumb"><img src={investment.user.profile} alt="image" /></div>
                       <div className="content">
                         <span className="name">{investment.user.name}</span>
                       </div>
                     </div>
                   </td>
                   <td>
                     <span className="price">{getAmount(investment.amount_invested)}</span>
                   </td>
                   
                   <td>
                     <span className="daily-dividend">{investment.source}</span>
                   </td>
                  
                   <td>
                     <span className="remaining-time">{investment.plan.duration}</span>
                     <a href="https://account.triumph-fx.com" className="btn btn-primary btn-round pull-right">invest now</a>
                   </td>
                 </tr>
                 ))}
                 
               </tbody>
             </table>
           </div>
           <div className="btn-area mt-50 text-center">
             <a href="https://account.triumph-fx.com" className="btn btn-primary btn-hover text-small">browse more</a>
           </div>
         </div>
       </div>
     </div>
     </div>
      }
      
</section>


  )
}

export default Invest
