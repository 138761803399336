import React from 'react'
import RealestatePlan from '../components/Home/RealestatePlan'

const Realestate = () => {
  return (
   <div>
  <section className="inner-page-banner-section gradient-bg">
    <div className="illustration-img"><img src="assets/images/inner-page-banner-illustrations/investment.png" alt="image-illustration" /></div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="inner-page-content-area">
            <h2 className="page-title">Real Estate Investment</h2>
            <nav aria-label="breadcrumb" className="page-header-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Home</a></li>
                <li className="breadcrumb-item">Real Estate</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </section>
  <RealestatePlan/>
</div>

  )
}

export default Realestate
