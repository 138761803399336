import React from 'react'

const Affiliate = () => {
  return (
    <section className="affiliate-features-section pt-120 pb-120">
  <div className="shape"><img src="assets/images/elements/affiliate-shape.png" alt="image" /></div>
  <div className="container">
    <div className="row">
      <div className="col-xl-6">
        <div className="affiliate-features-content text-xl-left text-center">
          <div className="section-header">
            <span className="section-subtitle">Earn The Big Money</span>
            <h2 className="section-title">Affiliate Program</h2>
            <p>Our affiliate program can increase your income by receiving percentage from the purchases made by your referrals into. </p>
          </div>
          <p>Invite other users (for example, your friends, co-workers, etc.) to join the project. After registration they will be your referrals; and if they purchase any item on our web site you receive your reward.</p>
          <a href="{{url('/affiliate')}}" className="btn btn-primary btn-hover text-small">read more</a>
        </div>
      </div>
      <div className="col-xl-6">
        <div className="row mb-none-30">
          <div className="col-xl-6 col-lg-4 col-md-6">
            <div className="affiliate-features-item text-center mb-30">
              <div className="icon"><img src="assets/images/icons/affiliate-features/1.svg" alt="image" /></div>
              <span className="subtitle">Enjoy unlimited</span>
              <h3 className="title">Commissions</h3>
              <p>The more User you refer, the more commissions we’ll pay you. Plain and simple.</p>
            </div>
          </div>{/* affiliate-features-item end */}
          <div className="col-xl-6 col-lg-4 col-md-6">
            <div className="affiliate-features-item text-center mb-30">
              <div className="icon"><img src="assets/images/icons/affiliate-features/2.svg" alt="image" /></div>
              <span className="subtitle">Extra Bonus and</span>
              <h3 className="title">Promotions</h3>
              <p>Boost your monthly earnings with additional promotional opportunities.</p>
            </div>
          </div>{/* affiliate-features-item end */}
          <div className="col-xl-6 col-lg-4 col-md-6">
            <div className="affiliate-features-item text-center mb-30">
              <div className="icon"><img src="assets/images/icons/affiliate-features/3.svg" alt="image" /></div>
              <span className="subtitle">Get top notch</span>
              <h3 className="title">Support</h3>
              <p>Maximize your earning potential with our popular amazing support center.</p>
            </div>
          </div>{/* affiliate-features-item end */}
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Affiliate
