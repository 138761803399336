import React from 'react'

const Footer = () => {
  return (
    
   <footer className="footer-section">
      <div className="footer-top bg_img" data-background="https://res.cloudinary.com/dzxyvdq14/image/upload/v1693579661/footerlinks.png">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-header text-white text-center">
                <span className="section-subtitle">Subscribe us</span>
                <h2 className="section-title">For Newsletter</h2>
                <p>Join 14,000+ satisfied Fast Invest customers! <a href="https://account.triumph-fx.com/">Register</a> and Subscribe to our newsletter to receive all the latest news and updates. </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="subscribe-wrapper">
                <span className="icon wow zoomIn" data-wow-duration="0.3s" data-wow-delay="0.5s"><img src="assets/images/icons/subscribe.png" alt="icon" /></span>
                <form className="subscribe-form">
                  <input type="text" name="subs_name" id="subs_name" placeholder="Your Email Address" />
                  <button type="submit" className="subs-btn">subscribe<span className="btn-icon"><img src="assets/images/icons/paper-plane.png" alt="icon" /></span></button>
                </form>
              </div>
            </div>
          </div>
          <div className="row mb-none-30">
            <div className="col-lg-3 col-sm-6">
              <div className="footer-widget mb-30">
                <h3 className="widget-title">About Triumph FX</h3>
                <ul className="footer-menu-list">
                  <li><a href="/about">About us</a></li>
                  <li><a href="/contact">Contact Us</a></li>
                  <li><a href="/">Privacy Policy</a></li>
                </ul>
              </div>
            </div>{/* footer-widget end */}
            <div className="col-lg-3 col-sm-6">
              <div className="footer-widget mb-30">
                <h3 className="widget-title">My Account</h3>
                <ul className="footer-menu-list">
                  <li><a href="https://account.triumph-fx.com/register">Register</a></li>
                  <li><a href="https://account.triumph-fx.com/">Login</a></li>
                  <li><a href="https://account.triumph-fx.com/">Withdrawal</a></li>
                </ul>
              </div>
            </div>{/* footer-widget end */}
            <div className="col-lg-3 col-sm-6">
              <div className="footer-widget mb-30">
                <h3 className="widget-title">help center</h3>
                <ul className="footer-menu-list">
                  <li><a href="/faq">How It Works</a></li>
                  <li><a href="/contact">Contact Us</a></li>
                </ul>
              </div>
            </div>{/* footer-widget end */}
            <div className="col-lg-3 col-sm-6">
              <div className="footer-widget mb-30">
                <h3 className="widget-title">legal info</h3>
                <ul className="footer-menu-list">
                  <li><a href="/">Terms &amp; Conditions</a></li>
                  <li><a href="">Legal Documents</a></li>
                </ul>
              </div>
            </div>{/* footer-widget end */}
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <p className="copy-right-text text-md-left text-center mb-md-0 mb-3">Copyright © 2023.All Rights Reserved By <a href="">Triumph FX</a></p>
            </div>
            <div className="col-md-6">
              <div className="card-list text-md-right text-center">
                <a href="https://account.triumph-fx.com/"><img src="assets/images/icons/card-options/americanexpress.png" alt="image" /></a>
                <a href="https://account.triumph-fx.com/"><img src="assets/images/icons/card-options/maestro.png" alt="image" /></a>
                <a href="https://account.triumph-fx.com/"><img src="assets/images/icons/card-options/visa.png" alt="image" /></a>
                <a href="https://account.triumph-fx.com/"><img src="assets/images/icons/card-options/paypal.png" alt="image" /></a>
                <a href="https://account.triumph-fx.com/"><img src="assets/images/icons/card-options/mastercard.png" alt="image" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>


  )
}

export default Footer
