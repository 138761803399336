import React from 'react'
import GoldPlan from '../components/Home/GoldPlan'

const GoldMine = () => {
  return (
    <div>
      <div>
  <section className="inner-page-banner-section gradient-bg">
    <div className="illustration-img"><img src="assets/images/inner-page-banner-illustrations/investment.png" alt="image-illustration" /></div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="inner-page-content-area">
            <h2 className="page-title">Invest in Gold Mining</h2>
            <nav aria-label="breadcrumb" className="page-header-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="{{url('/')}}">Home</a></li>
                <li className="breadcrumb-item">Sports</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </section>
 <GoldPlan/>
</div>

    </div>
  )
}

export default GoldMine
