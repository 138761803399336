import React, {useEffect, useState} from 'react'
import apiClient from '../../request/http-common';

const Transactions = () => {
  const convertToString = (text) => text.toString();
  const [withdrawals, setwithdrawals] = useState([]);
  const getAmount = (amount) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);

    useEffect(() => {
    apiClient.get('/api/investment-plans')
        .then((response) => {
          if(response.data.status === 'success'){
             setwithdrawals(response.data.withdrawals);        
          }
        });
  }, []);

  return (
    <section className="latest-transaction-section pt-120 pb-120" style={{backgroundColor: '#041812'}}>
    
  <div className="elemets-bg" data-paroller-factor="-0.2" data-paroller-type="foreground" data-paroller-direction="vertical"><img src="assets/images/withdraw-bg.png" alt /></div>
  {withdrawals.length > 0 &&
    
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-8">
        <div className="section-header text-center">
          <span className="section-subtitle text-white">Latest Transaction</span>
          <h2 className="section-title text-white">Withdrawals</h2>
          <p style={{color: '#e5f3ef'}}>Our goal is to simplify investing so that anyone can be an investor.Withthis in mind, we  hand-pick the investments we offer on our platform.</p>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
        <div className="tab-content" id="transactionTabContent">
          <div className="tab-pane fade show active" id="daily" role="tabpanel" aria-labelledby="daily-tab">
            <div className="withdraw-table-area">
              <table>
                <thead>
                  <tr>
                    <th>name</th>
                    <th>Amount</th>
                    <th>Source</th>
                    <th>Coin</th>
                    <th>Address</th>
                  </tr>
                </thead>
                <tbody>
                  {withdrawals.map(withdrawal => (
                    <tr key={withdrawal.id}>
                    <td data-head="name">
                      <div className="person-details">
                        <div className="thumb"><img src="assets/images/withdraw/t-1.png" alt="image" /></div>
                        <div className="content">
                          <span className="name">{withdrawal.user.name}</span>
                        </div>
                      </div>
                    </td>
                    <td data-head="price">
                      <span className="price">{getAmount(withdrawal.amount)}</span>
                    </td>
                    <td data-head="daily dividend">
                      <span className="daily-dividend">{withdrawal.source}</span>
                    </td>
                    <td data-head="amounts">
                      <span className="amount">{withdrawal.coin_name}</span>
                    </td>
                    <td data-head="Deposit by">
                      <span className="days">{withdrawal.address}</span>
                    </td>
                    {/* <td data-head="Currency">
                      <img src="assets/images/icons/withdraw/bitcoin.png" alt="icon" />
                    </td> */}
                  </tr>
                  ))}
                  
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="btn-area text-center">
          <a href="#0" className="btn btn-primary btn-hover text-small">browse more</a>
        </div>
      </div>
    </div>
  </div>
}
</section>


  )
}

export default Transactions
