import React from 'react'

const Community = () => {
  return (
    <section className="community-section bg_img pt-120" data-background="assets/images/community-bg.png">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-10">
        <div className="section-header text-center text-white wow fadeIn" data-wow-duration="0.8s" data-wow-delay="0.5s">
          <span className="section-subtitle">We support</span>
          <h2 className="section-title">Cryptocurrency Community</h2>
          <p>Access a world of dynamic investment opportunities, buy into businesses you believe in and share in their  success.You may make additional deposits at any time. All our 
            payments are instant payments.</p>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
        <div className="community-wrapper">
          <div className="row">
            <div className="col-lg-7">
              <div className="community-item">
                <div className="icon">
                  <img src="assets/images/icons/community/1.svg" alt="image" />
                </div>
                <div className="content">
                  <h3 className="title">Simplicity</h3>
                  <p>We’re eliminating complex user experiences.</p>
                  <a href="https://account.triumph-fx.com" className="read-more-btn">read more<i className="icofont-long-arrow-right" /></a>
                </div>
              </div>{/* community-item end */}
              <div className="community-item">
                <div className="icon">
                  <img src="assets/images/icons/community/2.svg" alt="image" />
                </div>
                <div className="content">
                  <h3 className="title">security</h3>
                  <p>Enhanced security features like multi-factor </p>
                  <a href="https://account.triumph-fx.com" className="read-more-btn">read more<i className="icofont-long-arrow-right" /></a>
                </div>
              </div>{/* community-item end */}
              <div className="community-item">
                <div className="icon">
                  <img src="assets/images/icons/community/3.svg" alt="image" />
                </div>
                <div className="content">
                  <h3 className="title">support</h3>
                  <p>Get all the support you need for your Investment</p>
                  <a href="https://account.triumph-fx.com" className="read-more-btn">read more<i className="icofont-long-arrow-right" /></a>
                </div>
              </div>{/* community-item end */}
            </div>
            <div className="col-lg-5">
              <div className="user-wrapper">
                <div className="icon">
                  <img src="assets/images/icons/community/user-icon.svg" alt="image" />
                </div>
                <span className="caption">18000+ Users</span>
                <div className="users-area">
                  <span className="user-img"><img src="assets/images/users/s1.png" alt="image" /></span>
                  <span className="user-img"><img src="assets/images/users/s2.png" alt="image" /></span>
                  <span className="user-img"><img src="assets/images/users/s3.png" alt="image" /></span>
                  <span className="user-img"><img src="assets/images/users/s4.png" alt="image" /></span>
                  <span className="user-img"><img src="assets/images/users/s5.png" alt="image" /></span>
                  <span className="user-img"><img src="assets/images/users/s6.png" alt="image" /></span>
                  <span className="user-img"><img src="assets/images/users/s7.png" alt="image" /></span>
                  <a href="#" className="btn btn-primary btn-round">see all</a>
                </div>
              </div>
              <div className="btn-area text-center">
                <a href="https://account.triumph-fx.com" className="btn btn-secondary">join us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </div>
</section>


  )
}

export default Community
