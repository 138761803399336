import React from 'react'
import Banner from './Banner';
import Counter from './Counter';
import WhyChooseUs from './WhyChooseUs';
import Features from './Features';
import Invest from './Invest';
import Offer from './Offer';
import Profit from './Profit';
import Deposit from './Deposit';
import Community from './Community';
import Transactions from './Transactions';
import Affiliate from './Affiliate';
import Testimonial from './Testimonial';
import Investors from './Investors';
import ContextWinner from './ContextWinner';
import Partner from './Partner';
import Banner2 from './Banner2';
import Offers from './Offers';
import RealestatePlan from './RealestatePlan';
import SportsPlan from './SportsPlan';
import GoldPlan from './GoldPlan';
import CryptoPlan from './CryptoPlan';
import TradingViewWidget from './TradingView/HeatMapView';

const Index = () => {
  return (
    <div>
      <Banner2/>
      <Counter/>
      <Offers/>
      <TradingViewWidget/>
      <WhyChooseUs/>
      {/* <RealestatePlan/>
      <SportsPlan/>
      <GoldPlan/> */}
      <CryptoPlan/>
      <Features/>
      <Invest/>
      <Offer/>
      <Profit/>
      <Deposit/>
      <Community/>
      <Transactions/>
      <Affiliate/>
      <Testimonial/>
      <Investors/>
      <ContextWinner/>
      <Partner/>
    </div>
  )
}

export default Index
