import logo from './logo.svg';
import './App.css';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import SignIn from './components/Home/SignIn';
import SignUp from './components/Home/SignUp';
import Index from './components/Home/Index';
import { Routes, Route } from "react-router-dom";
import About from './pages/About';
import { useEffect } from 'react';
import Affiliate from './pages/Affiliate';
import Packages from './pages/Packages';
import Contact from './pages/Contact';
import Realestate from './pages/Realestate';
import Sports from './pages/Sports';
import GoldMine from './pages/GoldMine';

function App() {

  return (
    <div className="App">
      <SignIn/>
      <SignUp/>
      <Header/>
      <Routes>
        <Route path='/' element={<Index/>} />
        <Route path='/about' element={<About/>} />
        <Route path='/affiliate' element={<Affiliate/>} />
        <Route path='/packages' element={<Packages/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/real-estate' element={<Realestate/>} />
        <Route path='/sports' element={<Sports/>} />        
        <Route path='/gold-mine' element={<GoldMine/>} />        
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
